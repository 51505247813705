import Grid from "@mui/material/Grid2";
import {Button, Typography} from "@mui/material";
import React from "react";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const ContractFiles = () => {
    const headers = ["Název", "Velikost", "Akce"];
    const files = [
        { name: "example.txt", size: "12Kb"},
        { name: "example.txt", size: "12Kb"},
        { name: "example.txt", size: "12Kb"},
        { name: "example.txt", size: "12Kb"},
        { name: "example.txt", size: "12Kb"},
        { name: "example.txt", size: "12Kb"},
        { name: "example.txt", size: "12Kb"},
        { name: "example.txt", size: "12Kb"},
        { name: "example.txt", size: "12Kb"},
    ];

    return (
        <Grid container spacing={2} justifyContent="space-around">
            <Grid size={12} sx={{display: "flex", alignItems: "end", justifyContent: "end"}}>
            <Button
                component="label"
                size="small"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
            >
                Nahrát soubory
                <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => console.log(event.target.files)}
                    multiple
                />
            </Button>
            </Grid>
            {headers.map((header, index) => (
                <Grid key={index} size={4}>
                    <Typography fontWeight="bold">{header}</Typography>
                </Grid>
            ))}
            {files.map((file, index) => (
                <React.Fragment key={index}>
                    <Grid size={4}>
                        <Typography>{file.name}</Typography>
                    </Grid>
                    <Grid size={4}>
                        <Typography>{file.size}</Typography>
                    </Grid>
                    <Grid size={4}>
                        <FileDownloadIcon/>
                        <DeleteIcon sx={{ ml: 1 }}/>
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
};