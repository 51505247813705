import React, { useEffect, useState} from 'react';
import EntityDetail from "../../components/EntityDetail";
import Sidebar from "../../components/Sidebar";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {useAuthHeader} from "react-auth-kit";
import toast from "react-hot-toast";


const CompanyDetail = () => {
  const [company, setCompany] = useState(null);
  const {id} = useParams();
  const getAuthHeader = useAuthHeader();
  const navigate = useNavigate();

  const fetchCompany = async (id) => {
    try {
      const token = getAuthHeader();
      if (!token) {
        navigate("/");
        toast.error("Přihlášení vypršelo!");
        return
      }
      const response = await axios.post(
          process.env.REACT_APP_FLEXIOVERVIEW_API_URL + `/companyReport/get`,
        {companyCode: id},
        {headers: {Authorization: token}}
      );
      setCompany({name: response.data.report.companyDetail.name, data: response.data});
    } catch (error) {
      console.error(`Error fetching detailed data:`, error);
      throw error;
    }
  };

  useEffect(() => {
    id && fetchCompany(id);
  }, [id]);

  return (
    <Sidebar>
        <EntityDetail
          entity={company && company.data ? company : null}
          entityName="Firmy"
          apiPath="/company"
          navPath="/companies"
          isCompany={true}
          dateFrom={company && company.data ? company.data.report.companyDetail.timeInterval.from : null}
          dateTo={company && company.data  ? company.data.report.companyDetail.timeInterval.to : null}
        />
    </Sidebar>
  );
};

export default CompanyDetail;