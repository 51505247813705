import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grid from "@mui/material/Grid2";
import {Button, IconButton,Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%', // Default width for both PC and mobile
  maxWidth: '90vh', // Maximum width for PC
  maxHeight: '90vh', // Adjust the height as needed
  overflowY: 'auto', // Enable vertical scrolling
  boxShadow: 24,
  borderRadius: "10px",
  bgcolor: 'background.paper',
};

const BudgePopUP = ({opened, handleClose, children, handleSave}) => {
  return (
    <Modal
      open={opened}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} component="form" onSubmit={handleSave}>
        <Box sx={{position: 'sticky', top: 0, backgroundColor: '#ffffff', zIndex: 1}}>
          <Grid container>
            <Grid size={6}>
              <Typography variant="h5" sx={{p: 3, fontWeight: 'bold'}}>
                Úprava zakázky
              </Typography>
            </Grid>
            <Grid size={6} sx={{display: 'flex', justifyContent: 'flex-end', p: 1.5}}>
              <IconButton onClick={handleClose}>
                <CloseIcon/>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{p: 3, backgroundColor: '#f8f8f8'}}>
          {children}
        </Box>
        <Box sx={{position: 'sticky', bottom: 0, backgroundColor: '#ffffff'}}>
          <Grid container sx={{p: 3}}>
            <Grid size={6}/>
            <Grid size={6} sx={{display: 'flex', justifyContent: 'flex-end', gap: 2}}>
              <Button variant="outlined" onClick={handleClose} startIcon={<CloseIcon/>}>
                Zrušit
              </Button>
              <Button variant="contained" type="submit" endIcon={<SaveIcon/>}>
                Uložit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default BudgePopUP;
