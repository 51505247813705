import { useState} from "react";
import Grid from "@mui/material/Grid2";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Select, Switch,
  TextField
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export const UserInfo =({ formik }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid size={6}>
        Jméno:
        <TextField
          name="firstname"
          variant="outlined"
          size="small"
          fullWidth
          value={formik.values.firstname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.firstname && Boolean(formik.errors.firstname)}
          helperText={formik.touched.firstname && formik.errors.firstname}
        />
      </Grid>
      <Grid size={6}>
        Příjmení:
        <TextField
          name="lastname"
          variant="outlined"
          size="small"
          fullWidth
          value={formik.values.lastname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.lastname && Boolean(formik.errors.lastname)}
          helperText={formik.touched.lastname && formik.errors.lastname}
        />
      </Grid>
      <Grid size={6}>
        E-mail
        <TextField
          name="username"
          variant="outlined"
          size="small"
          fullWidth
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
      </Grid>
      <Grid size={6}>
        Role:
        <br/>
        <FormControl fullWidth error={formik.touched.role && Boolean(formik.errors.role)}>
          <Select
            labelId="role-label"
            id="role"
            name="role"
            size="small"
            fullWidth
            value={formik.values.role}
            onChange={formik.handleChange}
            helperText={formik.touched.role && formik.errors.role}
            onBlur={formik.handleBlur}
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="user">User</MenuItem>
          </Select>
          <FormHelperText>{formik.touched.role && formik.errors.role}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid size={6}>
        Heslo:
        <TextField
          name="password"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          size="small"
          fullWidth
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((show) => !show)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid size={6}>
        Heslo znovu:
        <TextField
          name="confirmPassword"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          size="small"
          fullWidth
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((show) => !show)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid size={6}>
        Aktivní:
        <br/>
        <FormControlLabel
          control={
            <Switch
              checked={formik.values.active}
              onChange={(event) => formik.setFieldValue("active", event.target.checked)}
              name="active"
              color="primary"
            />
          }
          label={formik.values.active ? "Ano" : "Ne"}
        />
      </Grid>
    </Grid>
  )
};