export function removeTimezone(dateString) {
  if (!dateString) return ''; // Return an empty string if dateString is invalid

  const plusIndex = dateString.indexOf('+');
  if (plusIndex !== -1) {
    return dateString.slice(0, plusIndex);
  }
  return dateString;
}

export function formatAmount(number) {
  if (number === null || number === '' || isNaN(number)) {
    return '';
  }
  let roundedNumber = Math.round(number); // Round to the nearest whole number
  return roundedNumber.toLocaleString('cs-CZ'); // Format using locale without decimal places
}

export function formatDate(dateString) {
  if (!dateString) return ''; // Return an empty string if dateString is invalid

  const dateWithoutTimezone = removeTimezone(dateString);
  const date = new Date(dateWithoutTimezone);

  if (isNaN(date)) return ''; // Check if date is invalid

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}

export function formatDateAddSlash(input) {
  if (input.length !== 4) {
    return input;
  }
  return `${input.slice(0, 2)}/${input.slice(2)}`;
}

export function formatCompanyName(inputString) {
  if (!inputString) return "";

  return inputString
    .replace(/_s_r_o_$/i, " s.r.o.") // Replace trailing "_s_r_o_" with " s.r.o."
    .replace(/_z_s_$/i, " z.s.") // Replace trailing "_z_s_" with " z.s."
    .replace(/_a_s_$/i, " a.s.") // Replace trailing "_a_s_" with " a.s."
    .replace(/_a_s_/gi, " a.s. ") // Replace inline "_a_s_" with " a.s. "
    .replace(/_/g, " ") // Replace remaining underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
    .replace(/\bS\.R\.O\./g, "s.r.o.") // Ensure "s.r.o." stays lowercase
    .replace(/\bZ\.S\./g, "z.s.") // Ensure "z.s." stays lowercase
    .replace(/\bA\.S\./g, "a.s.") // Ensure "a.s." stays lowercase
    .trim(); // Remove leading/trailing spaces
}

export function formatStatus(status) {
  switch (status) {
    case 'not-paid':
      return 'Neuhrazeno';
    case 'partially-paid':
      return 'Částečně uhrazeno';
    case 'fully-paid':
      return 'Uhrazeno';
    case '':
      return '';
    default:
      return 'Neznámý status';
  }
}


