import React, {useContext, useEffect, useRef, useState} from 'react';
import {Box, Card, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import debounce from 'lodash.debounce';
import axios from "axios";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useAuthHeader} from "react-auth-kit";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {csCZ} from '@mui/x-date-pickers/locales';
import 'dayjs/locale/cs';
import BudgetTable from "../components/BudgetTable";
import CashflowIncomes from "../components/CashflowIncomes";
import CashflowOutcomes from "../components/CashflowOutcomes";
import Contracts from "../components/Contracts";
import Phases from "../components/Phases";
import BankTable from "./BankTable";
import {GlobalDataContext} from "./GlobalDataProvider";
import {toast} from "react-hot-toast";
import {RefreshButton} from "./RefreshButton";
import {convertDateToMMYY, convertDateToMMYYDayjs, convertStringToDate} from "../utils/Coverter";
import {generateMonthArray} from "../utils/ValueGenerator";
import {formatStatus} from "../utils/Formater";
import dayjs from "dayjs";

const cardTitles = [
  'Cashflow',
  'Rozpočet',
  'Zakázky',
  'F0',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  'F7',
];

//TODO: Move to CashflowOutcomes
const phases = [
  {
    "code": "F0",
    "name": "F0 - Nákup"
  },
  {
    "code": "F1",
    "name": "F1 - Projekční práce"
  },
  {
    "code": "F2",
    "name": "F2 - Stavba"
  },
  {
    "code": "F3",
    "name": "F3 - Projektové řízení"
  },
  {
    "code": "F4",
    "name": "F4 - Marketing a prodej"
  },
  {
    "code": "F5",
    "name": "F5 - Pozáruční servis"
  },
  {
    "code": "F6",
    "name": "F6 - Firma"
  },
  {
    "code": "F7",
    "name": "F7 - Finanční náklady"
  },
  {
    "code": "Total",
    "name": "Celkem"
  }
];

//TODO: Move to CashflowIncomes
//Sale and rent removed based on client request
const incomeTypes = [
  {
    "code": "INVESTICE",
    "name": "Investice"
  },
  {
    "code": "PŮJČKA - BANKA",
    "name": "Půjčka - banka"
  },
  {
    "code": "PŮJČKA - SKUPINA",
    "name": "Půjčka - skupina"
  },

  {
    "code": "Total",
    "name": "Celkem"
  },
  {
    "code": "dphImpact",
    "name": "Vliv DPH"
  },
  {
    "code": "cashflow",
    "name": "Čistý finanční tok"
  },
]

const EntityDetail = ({entity, entityName, apiPath, navPath, dateFrom, dateTo, isCompany}) => {
  const {globalUpdateContract} = useContext(GlobalDataContext);
  const {globalDeleteContract} = useContext(GlobalDataContext);
  const {globalUpdateUserValueList} = useContext(GlobalDataContext);
  const {updateMessage} = useContext(GlobalDataContext);
  const firstTableRef = useRef(null);
  const secondTableRef = useRef(null);
  const thirdTableRef = useRef(null);
  const [isRowsRendered, setIsRowsRendered] = useState(false);
  const [selectedCard, setSelectedCard] = useState('Cashflow');
  const [tableData, setTableData] = useState([]);
  const [entityData, setEntityData] = useState({});
  const [businessMonthFrom, setBusinessMonthFrom] = useState(dateFrom || "0124");
  const [businessMonthTo, setBusinessMonthTo] = useState(dateTo || "1224");
  const [dateError, setDateError] = useState(null);
  const {id} = useParams();
  const getAuthHeader = useAuthHeader();
  const navigate = useNavigate();

  const updateTimeInterval = async (from, to) => {
    try {
      const dayjsFrom = dayjs(convertStringToDate(from));
      const dayjsTo = dayjs(convertStringToDate(to));
      if (dayjsTo.isBefore(dayjsFrom)) {
        setDateError("Datum od musí být před datumem do!");
      }else {
        setDateError(null);
      }
      const token = getAuthHeader();
      if (!token) {
        navigate("/");
        toast.error("Přihlášení vypršelo!");
        return
      }
      await axios.post(process.env.REACT_APP_FLEXIOVERVIEW_API_URL + `/${apiPath}/update`, {
        costCenterCode: id, timeInterval: {
          from: from, to: to
        }, volumeStudyValueList: null, designBudgetValueList: null
      }, {headers: {Authorization: token}});
    } catch (error) {
      console.error('Error updating time interval:', error);
    }
  }
  const mapCompanyDataToTables = (data) => {
    if (!data || !data.contractList) {
      return {};
    }

    const tableData = [];
    const businessMonthSums = {};

    data.contractList
      .filter(contract => (contract.phaseList.includes(selectedCard)))
      .forEach((contract) => {
        const userValue = contract.userValueList.find(userValue => userValue.label === selectedCard);
        if (!userValue) {
          return;
        }

        const businessMonthList = userValue?.businessMonthScopeList
          ?.map(item => item.businessMonth)
          .filter(month => userValue.businessMonthAssumptionList.map(item => item.businessMonth).includes(month));

        businessMonthList.forEach((businessMonth) => {
          const scope = userValue.businessMonthScopeList?.find(item => item.businessMonth === businessMonth)?.value || 0;
          const assumption = userValue.businessMonthAssumptionList.find(item => item.businessMonth === businessMonth)?.value || 0;

          if (!businessMonthSums[businessMonth]) {
            businessMonthSums[businessMonth] = {
              assumptionValue: 0,
              scopeValue: 0,
              difference: 0,
              scope: 0,
              sumScope: 0,
              costAssumption: 0,
              sumCostAssumption: 0
            };
          }

          const contractScope = parseFloat(contract.scope) || 0;
          const contractCostAssumption = parseFloat(contract.costAssumption) || 0;

          businessMonthSums[businessMonth].assumptionValue += assumption;
          businessMonthSums[businessMonth].scopeValue += scope;
          businessMonthSums[businessMonth].difference += Math.abs(assumption - scope);
          businessMonthSums[businessMonth].scope += contractScope;
          businessMonthSums[businessMonth].sumScope += userValue.businessMonthScopeList?.reduce((sum, item) => sum + item.value, 0) || 0;
          businessMonthSums[businessMonth].costAssumption += contractCostAssumption;
          businessMonthSums[businessMonth].sumCostAssumption += userValue.businessMonthAssumptionList.reduce((sum, item) => sum + item.value, 0);

          const sumScope = userValue.businessMonthScopeList?.reduce((sum, item) => sum + item.value, 0)  || 0;
          const sumCostAssumption = userValue.businessMonthAssumptionList?.reduce((sum, item) => sum + item.value, 0) || 0;

          if (assumption !== 0 || scope !== 0 || sumScope !== contractScope || sumCostAssumption !== contractCostAssumption) {
            tableData.push({
              hierarchy: [formatDate(businessMonth), contract.code],
              id: `${contract.code}-${businessMonth}`,
              contractCode: contract.code,
              contractName: contract.description,
              businessMonth: businessMonth,
              assumptionValue: assumption,
              scopeValue: scope,
              difference: Math.abs(assumption - scope),
              level: "contract",
              scope: contractScope,
              sumScope: sumScope,
              costAssumption: contractCostAssumption,
              sumCostAssumption: sumCostAssumption
            });
          }
        });
      });

    let invoices = data.receivedInvoiceList.filter(
      item => (item.phaseList.includes(selectedCard)));

    const hierarchyTracker = {}; // Track occurrences of hierarchy combinations

    invoices.forEach((invoice) => {
      invoice.paymentList.forEach((payment) => {
        const paymentMonth = convertDateToMMYY(payment.paymentDate);

        const baseHierarchy = [formatDate(paymentMonth), invoice.contract, invoice.code];
        const hierarchyKey = baseHierarchy.join('-'); // Create a unique key for the hierarchy

        // Check and increment the counter for this hierarchy
        if (!hierarchyTracker[hierarchyKey]) {
          hierarchyTracker[hierarchyKey] = 0;
        }
        hierarchyTracker[hierarchyKey]++;

        // Append a suffix if this is not the first occurrence
        const uniqueInvoiceCode =
          hierarchyTracker[hierarchyKey] > 1
            ? `${invoice.code} (${hierarchyTracker[hierarchyKey]})`
            : invoice.code;

        const invoiceHasContract = tableData.some(row => row.id === `${invoice.contract}-${paymentMonth}`);

        if (!invoiceHasContract) {
          const contract = data.contractList.find(contract => contract.code === invoice.contract);
          const userValue = contract.userValueList.find(userValue => userValue.label === selectedCard);
          const scope = userValue?.businessMonthScopeList?.find(item => item.businessMonth === paymentMonth)?.value || 0;
          const assumption = userValue?.businessMonthAssumptionList?.find(item => item.businessMonth === paymentMonth)?.value || 0;
          tableData.push({
            hierarchy: [formatDate(paymentMonth), contract.code],
            id: `${contract.code}-${paymentMonth}`,
            contractCode: contract.code,
            contractName: contract.description,
            businessMonth: paymentMonth,
            assumptionValue: assumption,
            scopeValue: scope,
            difference: Math.abs(contract.costAssumption - contract.scope),
            level: "contract",
            scope: 0,
            sumScope: 0,
            costAssumption: 0,
            sumCostAssumption: 0
          });
        }

        tableData.push({
          hierarchy: [formatDate(paymentMonth), invoice.contract, uniqueInvoiceCode],
          id: `${invoice.contract}-${paymentMonth}-${uniqueInvoiceCode}-${payment.paymentDate}`,
          contractCode: invoice.contract,
          businessMonth: paymentMonth,
          level: "invoice",
          company: invoice.company,
          description: invoice.description,
          amountBase: invoice.amountBase,
          amountVat: invoice.amountVat,
          amountTotal: invoice.amountTotal,
          actualPayment: payment.paymentAmount,
          dueDate: invoice.dueDate,
          expoDate: invoice.expoDate,
          paymentDate: payment.paymentDate,
          statusPaid: formatStatus(invoice.statusPaid),
        });
      });
    });

    Object.keys(businessMonthSums).forEach(businessMonth => {
      const sums = businessMonthSums[businessMonth];

      if (sums.assumptionValue !== 0 || sums.scopeValue !== 0) {
        tableData.push({
          hierarchy: [formatDate(businessMonth)],
          id: businessMonth,
          contractCode: "",
          contractCodeLabel: "",
          level: "month",
          businessMonth: businessMonth,
          assumptionValue: sums.assumptionValue,
          scopeValue: sums.scopeValue,
          difference: sums.difference,
          scope: sums.scope,
          sumScope: sums.sumScope,
          costAssumption: sums.costAssumption,
          sumCostAssumption: sums.sumCostAssumption
        });
      }
    });

    // Sort tableData by businessMonth in MMYY format
    tableData.sort((a, b) => {
      const getDateFromMMYY = (mmyy) => {
        const month = parseInt(mmyy.substring(0, 2), 10) - 1; // JS months are 0-indexed
        const year = parseInt('20' + mmyy.substring(2, 4), 10); // assuming all dates are in the 2000s
        return new Date(year, month);
      };

      const dateA = getDateFromMMYY(a.businessMonth);
      const dateB = getDateFromMMYY(b.businessMonth);

      return dateA - dateB;
    });

    setTableData(prevTableData => ({
      ...prevTableData, [selectedCard]: tableData
    }));
  };

  function formatDate(input) {
    if (input.length !== 4) {
      return input;
    }
    return `${input.slice(0, 2)}/${input.slice(2)}`;
  }

  useEffect(() => {
    const firstTable = firstTableRef.current?.querySelector('.MuiDataGrid-virtualScroller');
    const secondTable = secondTableRef.current?.querySelector('.MuiDataGrid-virtualScroller');
    const thirdTable = thirdTableRef.current?.querySelector('.MuiDataGrid-virtualScroller');

    if (!firstTable && !secondTable && !thirdTable) return;

    const syncScroll = (source, targets) => {
      targets.forEach(target => {
        if (target) {
          target.scrollLeft = source.scrollLeft;
          target.scrollTop = source.scrollTop;
        }
      });
    };

    const createScrollHandler = (sourceTable, targetTables) => {
      if (!sourceTable) return null;
      return debounce(() => {
        syncScroll(sourceTable, targetTables);
      }, 60);
    };

    const handleFirstTableScroll = createScrollHandler(firstTable, [secondTable, thirdTable]);
    const handleSecondTableScroll = createScrollHandler(secondTable, [firstTable, thirdTable]);
    const handleThirdTableScroll = createScrollHandler(thirdTable, [firstTable, secondTable]);

    const addScrollListeners = (table, handler) => {
      if (table && handler) table.addEventListener('scroll', handler);
    };

    const removeScrollListeners = (table, handler) => {
      if (table && handler) table.removeEventListener('scroll', handler);
    };

    addScrollListeners(firstTable, handleFirstTableScroll);
    addScrollListeners(secondTable, handleSecondTableScroll);
    addScrollListeners(thirdTable, handleThirdTableScroll);

    return () => {
      removeScrollListeners(firstTable, handleFirstTableScroll);
      removeScrollListeners(secondTable, handleSecondTableScroll);
      removeScrollListeners(thirdTable, handleThirdTableScroll);
    };
  }, [selectedCard, isRowsRendered]);

  useEffect(() => {
    if (entity) setEntityData(entity.data)
  }, [entity]);

  useEffect(() => {
    if (!entityData || !entityData.report) {
      return;
    }
    mapCompanyDataToTables(entityData.report);
    if (selectedCard !== "cashflow") {
      setIsRowsRendered(false);
    }
  }, [selectedCard, entityData]);

  useEffect(() => {
    if (!entityData || !entityData.report) {
      return;
    }
    mapCompanyDataToTables(entityData.report);
  }, [entityData]);

  const handleDateFromChange = (newValue) => {
    if (!newValue){
      setBusinessMonthFrom(null);
      return
    }
    setBusinessMonthFrom(convertDateToMMYYDayjs(newValue.toString()));
    updateTimeInterval(convertDateToMMYYDayjs(newValue.toString()), businessMonthTo);
  };
  const handleDateToChange = (newValue) => {
    if (!newValue){
      setBusinessMonthTo(null);
      return;
    }
    setBusinessMonthTo(convertDateToMMYYDayjs(newValue.toString()));
    updateTimeInterval(businessMonthFrom, convertDateToMMYYDayjs(newValue.toString()));
  };

  const handleRowsChange = () => {
    setIsRowsRendered(true);
  };

  const handleLabelClick = (phase) => {
    setSelectedCard(phase.split(" ")[0]);
  }

  const updateOrAddContract = (oldCode, input, centreId) => {
    const updatedEntityData = {...entityData};
    const contractList = [...updatedEntityData.report.contractList];

    const existingIndex = contractList.findIndex(contract => contract.code === oldCode);

    if (existingIndex !== -1) {
      contractList[existingIndex] = {
        ...input,
       // userValueList: contractList[existingIndex].userValueList,
      };
    } else {
      contractList.push({
        ...input,
       // userValueList: [],
      });
    }

    updatedEntityData.report.contractList = contractList;

    setEntityData(prev => ({
      ...prev,
      report: {
        ...prev.report,
        contractList,
      },
    }));
    globalUpdateContract(input, oldCode, id, centreId);
  };

  const updateUserValueList = (code, newUserValueList, companyId, centreId) => {
    setEntityData(prev => {
      const contractList = prev.report.contractList;
      const contract = contractList.find(contract => contract.code === code);

      if (contract) {
        contract.userValueList = newUserValueList;
      }
      globalUpdateUserValueList(code, newUserValueList, companyId, centreId);
      return {...prev};
    });
  };

  const deleteContract = (codeToDelete, centreId) => {
    const updatedEntityData = {...entityData};

    updatedEntityData.report.contractList = updatedEntityData.report.contractList.filter(
      contract => contract.code !== codeToDelete
    );

    setEntityData(updatedEntityData);
    globalDeleteContract(codeToDelete, id, centreId);
  };


  return (
    <Box>
      <div className="container"
           style={{position: 'relative', width: "100%", justifyContent: "center", alignItems: "center"}}>
        <Grid container justifyContent="start">
          <Grid size={6}>
            <Typography variant="h5" fontWeight={600}>
              Detail {entityName.toLowerCase()} "{(entity && (entity?.data?.report?.costCenterDetail?.name)) ? entity?.data?.report?.costCenterDetail?.name : entity?.data?.report?.companyDetail?.name}"
            </Typography>
          </Grid>
          <Grid size={6} textAlign="right" sx={{height: '200%'}}>
            <Typography variant="text">
              {updateMessage}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography variant="h7" fontWeight={400}><Link to="/home" style={{
              textDecoration: 'none',
              color: 'black'
            }}>NOHO</Link> -> <Link to={navPath} style={{textDecoration: 'none', color: 'black'}}>{entityName}</Link> ->
              Detail </Typography>
          </Grid>
          <Grid size={6} textAlign="right">
            <RefreshButton/>
          </Grid>
        </Grid>
        <Box sx={{width: "100%", marginTop: "10px", backgroundColor: '#f5f5f5'}}>
          <Grid container sx={{overflowX: 'auto', padding: 1, marginBottom: 1}} justifyContent="start">
            {cardTitles.map((title) => (<Grid size={{xs: 4, md: 2, lg: 1}} key={title} sx={{height: '100%'}}>
              <Card onClick={() => setSelectedCard(title)} elevation={0}
                    sx={{
                      width: '100px',
                      borderRadius: '4px',
                      height: '40px',
                      display: 'flex',
                      flexDirection: 'column',
                      cursor: 'pointer',
                      backgroundColor: selectedCard === title ? '#EAEAEA' : '#f5f5f5',
                      color: selectedCard === title ? '#003366' : 'black'
                    }}>
                <Typography variant="h7" sx={{
                  fontSize: '0.75rem',
                  textTransform: 'uppercase',
                  display: 'flex',
                  width: 'auto',
                  height: '45px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  fontWeight: 600
                }}>
                  {title}
                </Typography>
              </Card>
            </Grid>))}
            {selectedCard === "Cashflow" && phases && phases?.length !== 0 && (
              <div style={{paddingTop: "15px"}}>
                <LocalizationProvider adapterLocale="cs" dateAdapter={AdapterDayjs}
                                      localeText={csCZ.components.MuiLocalizationProvider.defaultProps.localeText}>
                  <DatePicker
                    label="Od"
                    views={['year', 'month']}
                    value={convertStringToDate(businessMonthFrom)}
                    maxDate={convertStringToDate(businessMonthTo)}
                    onChange={handleDateFromChange}
                    slotProps={{
                      textField: {
                        onFocus: () => setBusinessMonthFrom(null),
                      },
                    }}
                    sx={{
                      width: "220px",
                      marginRight: "15px",
                      backgroundColor: "white",
                      borderRadius: "4px",
                    }}
                  />
                  <DatePicker
                    label="Do"
                    views={['year', 'month']}
                    value={convertStringToDate(businessMonthTo)}
                    minDate={convertStringToDate(businessMonthFrom)}
                    onChange={handleDateToChange}
                    slotProps={{
                      textField: {
                        onFocus: () => setBusinessMonthTo(null),
                      },
                    }}
                    sx={{width: "220px",marginRight: "15px", backgroundColor: "white", borderRadius: "4px"}}
                  />
                </LocalizationProvider>
              </div>
            )}
            <Grid size={12} p={0.5}>
              <Typography variant="text" sx={{pt: 1, color: 'red'}}>
                {dateError}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box>
          {
            selectedCard === "Cashflow" && entityData && entityData?.report && phases?.length > 0 ? (
              <>
                <Box sx={{marginTop: '20px'}}>
                  <CashflowOutcomes
                    data={entityData}
                    months={generateMonthArray(businessMonthFrom, businessMonthTo)}
                    rowDefinitions={phases}
                    reference={firstTableRef}
                    onRowsChange={handleRowsChange}
                    handleLabelClick={handleLabelClick}
                  />
                </Box>
                <Box sx={{marginTop: '20px'}}>
                  <CashflowIncomes
                    data={entityData}
                    months={generateMonthArray(businessMonthFrom, businessMonthTo)}
                    rowDefinitions={incomeTypes}
                    reference={secondTableRef}
                    onRowsChange={handleRowsChange}
                  />
                </Box>
                {isCompany && (
                  <Box sx={{marginTop: '20px'}}>
                    <BankTable
                      data={entityData}
                      months={generateMonthArray(businessMonthFrom, businessMonthTo)}
                      rowDefinitions={[{code: "vydej", name: "Výdaje"}, {code: "prijem", name: "Příjmy"}]}
                      reference={thirdTableRef}
                      onRowsChange={handleRowsChange}
                    />
                  </Box>
                )}
              </>
            ) : selectedCard === "Rozpočet" && phases?.length > 0 ? (
              entityData.report && <BudgetTable data={entityData} title="Náklady dle fáze"/>
            ) : selectedCard === "Zakázky" && phases?.length > 0 ? (
              <Contracts data={entityData} isCompany={isCompany} updateOrAddContract={updateOrAddContract}
                         updateUserValue={updateUserValueList} deleteContract={deleteContract}/>
            ) : (
              <Phases data={tableData[selectedCard]} isLoading={entity === null || !!entityData}
                      companyData={entityData}
                      updateUserValue={updateUserValueList}
                      selectedCard={selectedCard}
                      selectedTableData={tableData[selectedCard]} company={entity}/>
            )
          }
        </Box>
      </div>
    </Box>);
};

export default EntityDetail;
