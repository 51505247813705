import React, {useEffect, useState} from 'react';
import Sidebar from "../components/Sidebar";
import Typography from "@mui/material/Typography";
import {Link, useNavigate} from "react-router-dom";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Button, Paper} from "@mui/material";
import {userColumns} from "../data/DatatableColumns";
import Grid from "@mui/material/Grid2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UserEdit from "../components/popUps/user/UserEdit";
import {csCZ} from "@mui/x-data-grid/locales";
import toast from "react-hot-toast";
import axios from "axios";
import {useAuthHeader} from "react-auth-kit";

export default function Users() {
  const [popUpMode, setPopUpMode] = useState("");
  const [popUpOpened, setPopUpOpened] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const getAuthHeader = useAuthHeader();
  const navigate = useNavigate();

  const handleAddClick = () => {
    setPopUpMode("create");
    setPopUpOpened(true);
  }

  const handleEditClick = (id) => {
    let user = userList.find((u) => u.id === id);
    //FIXME: this is a temporary fix.
    if (!user.systemVisibilityList) {
      user.systemVisibilityList = [
        "Skupina",
        "Činnosti",
        "Střediska",
        "Firmy"
      ];
    }
    setSelectedUser(userList.find((u) => u.id === id));
    setPopUpMode("update");
    setPopUpOpened(true);
  }

  const handlePopUpClose = () => {
    setPopUpOpened(false);
  }

  useEffect(() => {
    fetchUsers();
  }, [])

  const fetchUsers = async () => {
    try {
      const token = getAuthHeader();
      if (!token) {
        navigate("/");
        toast.error("Přihlášení vypršelo!");
        return
      }
      const response = await axios.get(
          process.env.REACT_APP_FLEXIOVERVIEW_API_URL + `/user/list`,
        {headers: {Authorization: token}}
      );
      setUserList(response.data.userList)
    } catch (error) {
      console.error(`Error fetching detailed data:`, error);
      throw error;
    }
  };

  const updateUserList = (user) => {
    let updatedUserList = [...userList];
    const index = updatedUserList.findIndex((u) => u.id === user.id);
    if (index === -1) {
      updatedUserList.push(user);
    }else {
    updatedUserList[index] = user;
    }
    setUserList(updatedUserList);
  }

  const deleteUser = async (id) => {
    let updatedUserList = [...userList];
    updatedUserList = updatedUserList.filter((u) => u.id !== id);
    setUserList(updatedUserList);
  }

  return (
    <Sidebar>
      <Typography variant="h5" fontWeight="bold">Uživatelé</Typography>
      <Typography variant="h7"><Link to="/home" style={{textDecoration: 'none', color: 'black'}}>NOHO</Link> ->
        Uživatelé</Typography>
      <Paper sx={{marginTop: 2}}>
        <Grid container>
          <Grid size={6}>
            <Typography
              sx={{
                paddingLeft: 2,
                paddingTop: 1.7,
                paddingBottom: 1.7,
                fontWeight: 'bold',
                backgroundColor: 'white',
                position: 'sticky',
                left: 0,
                fontSize: "0.85rem",
                height: '45px',
              }}
            >
              Uživatelé
            </Typography>
          </Grid>
          <Grid size={6} alignItems="end"
                sx={{alignItems: "center", justifyContent: "end", display: "flex", paddingRight: 2}}>
            <Button variant="contained" onClick={handleAddClick} color="success"
                    sx={{fontSize: "14", padding: 0.5, paddingLeft: 2, paddingRight: 2}} startIcon={<AddCircleIcon/>}>
              Přidat
            </Button>
          </Grid>
        </Grid>
        <DataGridPro
          rows={userList}
          columns={userColumns(handleEditClick)}
          localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            minHeight: "77vh",
            '&.MuiDataGrid-root': {
              borderRadius: '0px',
              borderLeft: 'none',
              borderRight: 'none',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold', overflow: 'visible'
            },
          }}
          hideFooter
        />
      </Paper>
      <UserEdit mode={popUpMode} data={null} selectedUser={selectedUser} opened={popUpOpened} close={handlePopUpClose} updateRows={updateUserList} deleteRow={deleteUser}/>
    </Sidebar>
  );
}
