import React, {createContext, useState, useEffect} from 'react';
import axios from 'axios';
import {useAuthHeader, useAuthUser} from 'react-auth-kit';
import {useLocation, useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {convertDateToMMYY} from "../utils/Coverter";
import {removeTimezone} from "../utils/Formater";
import {generateMonthArray} from "../utils/ValueGenerator";
import dayjs from "dayjs";


export const GlobalDataContext = createContext();

export const GlobalDataProvider = ({children}) => {
  const [userDetails, setUserDetails] = useState(null);
  const [listOfCentres, setListOfCentres] = useState(null);
  const [listOfCompanies, setListOfCompanies] = useState(null);
  const [centresDetails, setCentresDetails] = useState(null);
  const [companiesDetails, setCompaniesDetails] = useState(null);
  const [groupsAndActivitiesCentres, setGroupsAndActivitiesCentres] = useState(null);
  const [groupsAndActivitiesCompanies, setGroupsAndActivitiesCompanies] = useState(null);
  const [isDataUpdating, setIsDataUpdating] = useState(false);
  const [updateMessage, setUpdateMessage] = useState("Načítám status...");
  const getAuthHeader = useAuthHeader();
  const authUser = useAuthUser();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getAuthHeader();
    if (token) {
      fetchUserDetails(token);
    }
  }, [getAuthHeader()]);

  useEffect(() => {
    const token = getAuthHeader();
    if (token) {
      if (listOfCentres === null && centresDetails === null) {
        fetchEntityList(token, true);
      }
      if (listOfCompanies === null && companiesDetails === null) {
        fetchEntityList(token, false);
      }
    }
  }, [getAuthHeader, listOfCentres, listOfCompanies, centresDetails, companiesDetails]);

  const fetchUserDetails = async (token) => {
    try {
      const userId = authUser().userId;
      let response = await axios.post(
          process.env.REACT_APP_FLEXIOVERVIEW_API_URL + `/user/get`,
        {userId},
        {headers: {Authorization: token}}
      );
      if (!response.data.systemVisibilityList) {
        response.data.systemVisibilityList = ["Skupina", "Činnosti", "Střediska", "Firmy"];
      }
      setUserDetails(response.data);
    } catch (error) {
      console.error(`Error fetching user details:`, error);
      throw error;
    }
  }

  const clearData = () => {
    setListOfCentres(null);
    setListOfCompanies(null);
    setCentresDetails(null);
    setCompaniesDetails(null);
    setGroupsAndActivitiesCentres(null);
    setGroupsAndActivitiesCompanies(null);
    setUserDetails(null);
  }

  const fetchEntityList = async (token, isCentre) => {
    try {
      const entityType = isCentre ? "costCenterReport" : "companyReport";
      const entityList = await fetchListOfEntities(token, entityType);
      isCentre ? setListOfCentres(entityList) : setListOfCompanies(entityList);
    } catch (error) {
      console.error(`Error fetching ${isCentre ? "centres" : "companies"} data:`, error);
    }
  };

  useEffect(() => {
    const token = getAuthHeader();
    if (token) {
      if ((location.pathname === '/groups' || location.pathname === '/activities' || location.pathname === '/tasks')
        && listOfCompanies
        && listOfCompanies.length > 0
        && companiesDetails === null
      ) {
        fetchEntityDetails(token, false);
      }
    }else {
      clearData();
    }
  }, [location.pathname, listOfCompanies]);

  const fetchEntityDetails = async (token, isCentre) => {
    try {
      const entityType = isCentre ? "costCenterReport" : "companyReport";
      const entityList = isCentre ? listOfCentres : listOfCompanies;
      const detailedData = await fetchEntityDetailedData(token, entityType, entityList);
      isCentre ? setCentresDetails(detailedData) : setCompaniesDetails(detailedData);
    } catch (error) {
      console.error(`Error fetching ${isCentre ? "centres" : "companies"} data:`, error);
    }
  };

  const fetchListOfEntities = async (token, entityType) => {
    try {
      const response = await axios.get(process.env.REACT_APP_FLEXIOVERVIEW_API_URL + `/${entityType}/list`, {
        headers: { Authorization: token },
      });
      return response.data.itemList.map(item => {
        // Check if code is numeric and, then pad it if single digit
        const code = /^\d+$/.test(item.code)
          ? item.code.padStart(2, '0')
          : item.code;
        return {
          id: item.code,
          code: code,
          name: item.name,
        };
      });
    } catch (error) {
      console.error(`Error fetching ${entityType} list:`, error);
      throw error;
    }
  };


  const fetchEntityDetailedData = async (token, entityType, entityList) => {
    try {
      const detailPromises = entityList.map(async entity => {
        const response = await axios.post(
            process.env.REACT_APP_FLEXIOVERVIEW_API_URL + `/${entityType}/get`,
          {[`${entityType === "costCenterReport" ? "costCenterCode" : "companyCode"}`]: entity.id},
          {headers: {Authorization: token}}
        );
        return {id: entity.id, name: entity.name, data: response.data};
      });
      const detailedData = await Promise.all(detailPromises);
      return detailedData.reduce((acc, {id, name, data}) => {
        acc[id] = {name, data};
        return acc;
      }, {});
    } catch (error) {
      console.error(`Error fetching detailed data for ${entityType}:`, error);
      throw error;
    }
  };

  useEffect(() => {
    if (centresDetails) processGroupsAndActivities(centresDetails, true);
    if (companiesDetails) processGroupsAndActivities(companiesDetails, false);
  }, [centresDetails, companiesDetails]);

  const processGroupsAndActivities = async (globalData, isCentre) => {
    const companyDataArray = await generateGroupsAndActivitiesData(globalData);
    isCentre ? setGroupsAndActivitiesCentres(companyDataArray) : setGroupsAndActivitiesCompanies(companyDataArray);
  };

  const generateGroupsAndActivitiesData = async (globalData) => {
    return Object.entries(globalData).map(([code, data]) => {
      const report = data.data.report;
      const group = extractGroup(report);

      const accountBalance = calculateAccountBalance(report.bankList);
      const monthlyBalances = calculateMonthlyBalances(report.bankList);

      const dataByMonth = aggregateDataByMonth(report);
      const assumptionByMonth = calculateAssumptions(report);

      const totalValues = calculateTotalValues(dataByMonth);
      const totalAssumptionValues = calculateTotalValues(assumptionByMonth);

      return {id: code, name: data.name, group, accountBalance, monthlyBalances,monthlyData: dataByMonth, totalValues, assumptionData: assumptionByMonth, totalAssumptionValues};
    });
  };

  function calculateMonthlyBalances(bankList) {
    if (!bankList || bankList.length === 0) {
      return {};
    }
    const monthlyBalances = {};
    const sortedTransactions = bankList.sort((a, b) => new Date(removeTimezone(a.expoDate)) - new Date(removeTimezone(b.expoDate)));
    let balance = 0.0;

    const monthArray = generateMonthArray(convertDateToMMYY(sortedTransactions[0].expoDate), convertDateToMMYY(dayjs().format('YYYY-MM-DDZ').toString()));

    sortedTransactions.forEach(transaction => {
      const { expoDate, amount, movementType } = transaction;
      const monthKey = convertDateToMMYY(expoDate);

      if (movementType === "prijem") {
        balance += amount;
        monthlyBalances[monthKey] = balance;
      } else if (movementType === "vydej") {
        balance -= amount;
        monthlyBalances[monthKey] = balance;
      }
    });

    let lastValidBalance = 0;
    monthArray.forEach(monthKey => {
      if (!monthlyBalances[monthKey]) {
        monthlyBalances[monthKey] = lastValidBalance;
      } else {
        lastValidBalance = monthlyBalances[monthKey];
      }
    });

    return monthlyBalances;

  }

  const calculateAccountBalance = (bankList) => {
    let balance = 0;
    bankList?.forEach(bank => {
      bank.movementType === 'vydej' ? balance -= bank.amount : balance += bank.amount;
    });
    return balance;
  }

  const extractGroup = (report) => {
    const firstContract = report.contractList?.[0];
    return firstContract?.groupList[0] || report.receivedInvoiceList?.[0]?.groupList[0] || "Ostatní";
  };

  const aggregateDataByMonth = (report) => {
    const dataByMonth = {};

    report.receivedInvoiceList?.forEach(invoice => {
      invoice.paymentList.forEach(payment => {
        const monthYear = convertDateToMMYY(payment.paymentDate);
        addToMonth(monthYear, isDPH(invoice.sourceList) ? 0 : -payment.paymentAmount, 'outcomes', dataByMonth);
        addToMonth(monthYear, isDPH(invoice.sourceList) ? -payment.paymentAmount : 0, 'dph', dataByMonth);

        invoice.sourceList.forEach(source => {
          addToMonth(monthYear, payment.paymentAmount || 0, getTranslatedCategory(source), dataByMonth);
        });

      });
    });

    report.claimList?.forEach(claim => {
      claim.paymentList.forEach(payment => {
        const monthYear = convertDateToMMYY(payment.paymentDate);

        addToMonth(monthYear, isDPH(claim.sourceList) ? 0 : payment.paymentAmount, 'incomes', dataByMonth);
        addToMonth(monthYear, isDPH(claim.sourceList) ? payment.paymentAmount : 0, 'dph', dataByMonth);

        claim.sourceList.forEach(source => {
          addToMonth(monthYear, payment.paymentAmount || 0, getTranslatedCategory(source), dataByMonth);
        });
      });
    });

    return dataByMonth;
  };

  const calculateAssumptions = (report) => {
    const assumptions = {};

    report.contractList?.forEach(contract => {
      contract.userValueList?.forEach(userValue => {
        userValue.businessMonthAssumptionList?.forEach(assumption => {
          const monthYear = assumption.businessMonth;
          addToMonth(monthYear, -assumption.value || 0, 'outcomes', assumptions);
          contract.sourceList.forEach(source => {
            addToMonth(monthYear, assumption.value || 0, getTranslatedCategory(source), assumptions);
          });
        });
      });
    });

    report.claimList?.forEach(claim => {
      const monthYear = convertDateToMMYY(claim.dueDate);
      const dphMonth = convertDateToMMYY(claim.accountingDate);
      addToMonth(monthYear, isDPH(claim.sourceList)? 0 : claim.amountTotal, 'incomes', assumptions);
      addToMonth(dphMonth, isDPH(claim.sourceList) ? 0 : -claim.amountVat, 'dph', assumptions);
      claim.sourceList.forEach(source => {
        addToMonth(monthYear, claim.amountTotal || 0, getTranslatedCategory(source), assumptions);
      });
    });

    report.receivedInvoiceList?.forEach(invoice => {
      const dphMonth = convertDateToMMYY(invoice.supplyDate);
      addToMonth(dphMonth, isDPH(invoice.sourceList) ? 0 : invoice.amountVat, 'dph', assumptions);
    });

    return assumptions;
  };

  const calculateTotalValues = (data) => {
    const totals = createEmptyMonthlyData();
    Object.values(data).forEach(monthlyData => {
      Object.keys(totals).forEach(key => {
        totals[key] += monthlyData[key];
      });
    });
    return totals;
  };

  const createEmptyMonthlyData = () => ({
    outcomes: 0,
    incomes: 0,
    dph: 0,
    investments: 0,
    sale: 0,
    rent: 0,
    loanBank: 0,
    loanGroup: 0,
  });

  const addToMonth = (dateString, value, category, target) => {
    if (!dateString) return;
    if (!target[dateString]) target[dateString] = createEmptyMonthlyData();
    target[dateString][category] += value || 0;
  };

  const CATEGORY_TRANSLATIONS = {
    'INVESTICE': 'investments',
    'PRODEJ': 'sale',
    'PRONÁJEM': 'rent',
    'PŮJČKA - BANKA': 'loanBank',
    'PŮJČKA - SKUPINA': 'loanGroup',
  };

  const getTranslatedCategory = (category) => {
    return CATEGORY_TRANSLATIONS[category] || category;
  };

  const isDPH = (sourceList) => {
    return sourceList.includes("DPH");
  };

  const globalUpdateContract = (contract, oldCode, companyId, centreId) => {
    if (!companiesDetails || !centresDetails) {
      return;
    }
    const company = companiesDetails[companyId].data;
    const updatedCompanyData = { ...company };
    const companyContractList = [...updatedCompanyData.report.contractList];

    const centre = centresDetails[centreId].data;
    const updatedCentreData = { ...centre };
    const centreContractList = [...updatedCentreData.report.contractList];

    const existingCompanyIndex = companyContractList.findIndex(contract => contract.code === oldCode);
    const existingCentreIndex = centreContractList.findIndex(contract => contract.code === oldCode);

    if (existingCompanyIndex !== -1) {
      companyContractList[existingCompanyIndex] = {
        ...contract,
       // userValueList: companyContractList[existingCompanyIndex].userValueList,
      };
    } else {
      companyContractList.push({
        ...contract,
       // userValueList: [],
      });
    }

    if (existingCentreIndex !== -1) {
      centreContractList[existingCentreIndex] = {
        ...contract,
      //  userValueList: centreContractList[existingCentreIndex].userValueList,
      };
    } else {
      centreContractList.push({
        ...contract,
       // userValueList: [],
      });
    }

    updatedCompanyData.report.contractList = companyContractList;
    updatedCentreData.report.contractList = centreContractList;

    setCompaniesDetails(prev => ({
      ...prev,
      [companyId]: {
        ...prev[companyId],
        updatedCompanyData,
      }
    }))

    setCentresDetails(prev => ({
      ...prev,
      [centreId]: {
        ...prev[centreId],
        updatedCentreData,
      }
    }))
  }

  const globalDeleteContract = (codeToDelete, companyId, centreId) => {
    if (!companiesDetails || !centresDetails) {
      return;
    }
    const company = companiesDetails[companyId].data;
    const updatedCompanyData = { ...company };

    const centre = centresDetails[centreId].data;
    const updatedCentreData = { ...centre };

    updatedCompanyData.report.contractList = updatedCompanyData.report.contractList.filter(
      contract => contract.code !== codeToDelete
    );

    updatedCentreData.report.contractList = updatedCentreData.report.contractList.filter(
      contract => contract.code !== codeToDelete
    );

    setCompaniesDetails(prev => ({
      ...prev,
      [companyId]: {
        ...prev[companyId],
        updatedCompanyData,
      }
    }))

    setCentresDetails(prev => ({
      ...prev,
      [centreId]: {
        ...prev[centreId],
        updatedCentreData,
      }
    }))

  }

  const globalUpdateUserValueList = (code, newUserValueList, companyId, centreId) => {
    if (!companiesDetails || !centresDetails) {
      return;
    }

    setCompaniesDetails(prev => {
      const company = prev[companyId]?.data;

      const contract = company.report.contractList.find(contract => contract.code === code);
      if (contract) {
        contract.userValueList = newUserValueList;
      }

      return {
        ...prev,
        [companyId]: {
          ...prev[companyId],
          data: { ...company },
        },
      };
    });

    setCentresDetails(prev => {
      const centre = prev[centreId]?.data;
      if (!centre) return prev;

      const contract = centre.report.contractList.find(contract => contract.code === code);
      if (contract) {
        contract.userValueList = newUserValueList;
      }

      return {
        ...prev,
        [centreId]: {
          ...prev[centreId],
          data: { ...centre },
        },
      };
    });
  };

  const getUpdatedDataTime = async () => {
    const token = getAuthHeader();
    if (!token) {
      navigate("/");
      toast.error("Přihlášení vypršelo!");
      return
    }
    const response = await axios.get(process.env.REACT_APP_FLEXIOVERVIEW_API_URL + `/dataset/get`, {
      headers: { Authorization: token },
    });

    if (response.data.updating === false) {
      setUpdateMessage(response.data.message);
      setIsDataUpdating(false)
      return;
    }

    setUpdateMessage(response.data.message);
    setIsDataUpdating(true);

    const interval = setInterval(async () => {
      try {
        const token = getAuthHeader();
        if (!token) {
          navigate("/");
          toast.error("Přihlášení vypršelo!");
          return
        }
        const response = await axios.get(process.env.REACT_APP_FLEXIOVERVIEW_API_URL + `/dataset/get`, {
          headers: { Authorization: token },
        });

        if (response.data.updating === false) {
          setIsDataUpdating(false);
          clearInterval(interval);
          clearData();
          setUpdateMessage(response.data.message);
          toast.success("Aktualizace byla dokončena!");
          navigate(0);
        }
      } catch (error) {
        setIsDataUpdating(false);
        setUpdateMessage("Při aktualizaci dat došlo k chybě. Zkuste to znovu...");
        clearInterval(interval);
      }
    }, 2000);
  };



  return (
    <GlobalDataContext.Provider value={{
      userDetails,
      listOfCentres,
      listOfCompanies,
      centresDetails,
      companiesDetails,
      groupsAndActivitiesCentres,
      groupsAndActivitiesCompanies,
      globalUpdateContract,
      globalDeleteContract,
      globalUpdateUserValueList,
      clearData,
      isDataUpdating,
      setIsDataUpdating,
      updateMessage,
      setUpdateMessage,
      getUpdatedDataTime,
    }}>
      {children}
    </GlobalDataContext.Provider>
  );
};
