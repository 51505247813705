import React from 'react';
import Grid from '@mui/material/Grid2';
import {formatAmount, removeTimezone} from "../../../utils/Formater";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import {FormControl, MenuItem, Select} from "@mui/material";

const FinancialInfo = ({showInfo, selectedContract, userValueList, setUserValueList, company, template, setTemplate}) => {

  const calculateTotalScope = () => {
    let totalScope = 0;
    userValueList.forEach(userValue => {
      userValue.businessMonthScopeList.forEach(scope => {
        totalScope += scope.value || 0;
      });
    });
    return totalScope;
  }

  const calculateTotalAssumption = () => {
    let totalAssumption = 0;
    userValueList.forEach(userValue => {
      userValue.businessMonthAssumptionList.forEach(assumption => {
        totalAssumption += assumption.value || 0;
      });
    });
    return totalAssumption;
  }

  const calculateActualPaymentSum = (contractCode) => {
    return company.receivedInvoiceList
      .filter((invoice) => invoice.contract === contractCode) // Match invoices by contractCode
      .reduce((sum, invoice) => {
        // Sum up all payments in the paymentList
        const totalPayments = invoice.paymentList
          ?.reduce((paymentSum, payment) => paymentSum + payment.paymentAmount, 0) || 0;

        return sum + totalPayments;
      }, 0);
  };

  const handleClearAssumption = () => {
    userValueList.forEach(userValue => {
      userValue.businessMonthAssumptionList.forEach(assumption => {
        assumption.value = 0;
      });
    });

    setUserValueList([...userValueList]);
    setTemplate("custom");
  };

  const handleClearScope = () => {
    userValueList.forEach(userValue => {
      userValue.businessMonthScopeList.forEach(scope => {
        scope.value = 0;
      });
    });

    setUserValueList([...userValueList]);
    setTemplate("custom");
  };


  return (
    <Grid container sx={{
      display: showInfo ? 'flex' : 'none',
      height: '100%',
      paddingBottom: '13px',
      position: 'sticky',
      top: -0.5,
      backgroundColor: '#f8f8f8',
      zIndex: 1,
      marginTop: -1,
      paddingTop: 2,
    }}>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px'}}>
          <strong>Kód zakázky:</strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px', textAlign: 'right'}}>
          {selectedContract.code}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px'}}>
          <strong>Číslo smlouvy:</strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px', textAlign: 'right'}}>
          {selectedContract.contractCode}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px'}}>
          <strong>Platí od:</strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px', textAlign: 'right'}}>
          {removeTimezone(selectedContract.startDate)}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px'}}>
          <strong>Platí do:</strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1" sx={{marginBottom: '10px', textAlign: 'right'}}>
          {removeTimezone(selectedContract.endDate)}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography
          variant="body1"
          sx={{marginBottom: '10px', color: selectedContract.costAssumption >= 0 ? 'inherit' : 'red'}}
        >
          <strong>Aktuální plán: </strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography
          variant="body1"
          sx={{
            marginBottom: '10px',
            textAlign: 'right',
            color: selectedContract.costAssumption >= 0 ? 'inherit' : 'red'
          }}
        >
          {formatAmount(selectedContract.costAssumption)}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2.5}>
        <Typography
          variant="body1"
          sx={{marginBottom: '10px', color: selectedContract.scope >= 0 ? 'inherit' : 'red'}}
        >
          <strong>Aktuální smlouva: </strong>
        </Typography>
      </Grid>
      <Grid size={1.5}>
        <Typography
          variant="body1"
          sx={{
            marginBottom: '10px',
            textAlign: 'right',
            color: selectedContract.scope >= 0 ? 'inherit' : 'red'
          }}
        >
          {formatAmount(selectedContract.scope)}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1" sx={{
          marginBottom: '10px',
          color: Math.abs(calculateTotalAssumption() - selectedContract.costAssumption) < 1 ? 'green' : 'red'
        }}>
          <strong>Nový plán: </strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1" sx={{
          marginBottom: '10px',
          color: Math.abs(calculateTotalAssumption() - selectedContract.costAssumption) < 1 ? 'green' : 'red',
          textAlign: 'right'
        }}>
          {formatAmount(calculateTotalAssumption())}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1" sx={{
          marginBottom: '10px',
          color: Math.abs(calculateTotalScope() - selectedContract.scope) < 1 ? 'green' : 'red'
        }}>
          <strong>Nová smlouva: </strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1" sx={{
          marginBottom: '10px',
          color: Math.abs(calculateTotalScope() - selectedContract.scope) < 1 ? 'green' : 'red',
          textAlign: 'right'
        }}>
          {formatAmount(calculateTotalScope())}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1" sx={{color: selectedContract.code >= 0 ? 'inherit' : 'red'}}>
          <strong>Realizováno: </strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1"
                    sx={{color: selectedContract.code >= 0 ? 'inherit' : 'red', textAlign: 'right'}}>
          {formatAmount(calculateActualPaymentSum(selectedContract.code))}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1"
                    sx={{color: (calculateTotalScope() - calculateActualPaymentSum(selectedContract.code)) >= 0 ? 'inherit' : 'red'}}>
          <strong>Zbývá
            dočerpat: </strong>
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography variant="body1"
                    sx={{
                      textAlign: 'right',
                      color: (calculateTotalScope() - calculateActualPaymentSum(selectedContract.code)) >= 0 ? 'inherit' : 'red'
                    }}>
          {formatAmount(calculateTotalScope() - calculateActualPaymentSum(selectedContract.code))}
        </Typography>
      </Grid>
      <Grid size={2}/>
      <Grid size={2}>
        <Typography variant="body1" sx={{color: 'inherit', paddingTop: 2.5}}>
          <strong>Šablona: </strong>
        </Typography>
      </Grid>
      <Grid size={10} sx={{color: 'inherit', paddingTop: 1.5}}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            value={template}
            onChange={(event) => setTemplate(event.target.value)}
            sx={{maxWidth: '200px'}}
          >
            <MenuItem value="evenly">Rovnoměrně</MenuItem>
            <MenuItem value="lastMonth">Na konci</MenuItem>
            <MenuItem value="firstMonth">Na začátku</MenuItem>
            <MenuItem value="firstAndLastMonth">Na konci a začátku</MenuItem>
            <MenuItem value="custom">Vlastní</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid size={1.5} marginTop={2}>
        <Typography variant="body2" fontWeight="bold">
          Fáze
        </Typography>
      </Grid>
      <Grid size={1.5} marginTop={2}>
        <Typography variant="body2" fontWeight="bold">
          Měsíc
        </Typography>
      </Grid>
      <Grid size={3.25} marginTop={2}>
        <div style={{alignItems: 'center', display: 'inline-flex', width: '100%'}}>
          <Typography variant="body2" fontWeight="bold" marginLeft={1}>
            Plán
          </Typography>
          <DeleteIcon onClick={() => handleClearAssumption()}
                      sx={{cursor: 'pointer', fontSize: "1.2rem", marginLeft: 1}}/>
        </div>
      </Grid>
      <Grid size={3.25} marginTop={2}>
        <div style={{alignItems: 'center', display: 'inline-flex', width: '100%'}}>
          <Typography variant="body2" fontWeight="bold" marginLeft={1.5}>
            Smlouva
          </Typography>
          <DeleteIcon onClick={() => handleClearScope()}
                      sx={{cursor: 'pointer', fontSize: "1.2rem", marginLeft: 1}}/>
        </div>
      </Grid>
      <Grid size={2.5} marginTop={2}>
        <Typography variant="body2" fontWeight="bold" marginLeft={1.5}>
          Realizováno
        </Typography>
      </Grid>
    </Grid>

  );
};

export default FinancialInfo;
