import React, {useState} from "react";
import {Paper, TextField, Button, Box, Typography} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import axios from "axios";
import LockIcon from '@mui/icons-material/Lock';
import toast from 'react-hot-toast';
import {useSignIn} from "react-auth-kit";
import {useNavigate} from "react-router-dom";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const signIn = useSignIn();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(process.env.REACT_APP_FLEXIOVERVIEW_API_URL + "/application/login", {
          username: username, password: password
        },
      );
      if (response.status === 200) {
        const expiresAt = new Date(response.data.session.expirationAt);
        const now = new Date();
        const ttlMilliseconds = expiresAt - now;
        const ttlMinutes = Math.floor(ttlMilliseconds / 60000);
        signIn({
          token: response.data.session.token,
          expiresIn: ttlMinutes,
          tokenType: "Bearer",
          authState: {username: username, userId: response.data.session.userId},
        });
        toast.success("Přihlášení bylo úspěšné!");
        navigate("/home");
      } else {
        toast.error('Přihlášení se nezdařilo')
      }
    } catch (error) {
      toast.error('Přihlášení se nezdařilo')
      console.error(error);
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Paper elevation={15} style={{paddingTop: 70, paddingBottom: 70, padding: 40, backgroundColor: '#efefef'}}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            textAlign: 'center',
            color: '#3a3a3a'
          }}
        >
          Přihlášení
        </Typography>
        <Box sx={{display: 'flex', alignItems: 'flex-end', paddingTop: 3, fontSize: 26}}>
          <AccountCircle sx={{color: '#3a3a3a', mr: 1, my: 0.5}}/>
          <TextField
            id="input-with-sx"
            label="Přihlašovací jméno"
            variant="standard"
            sx={{width: "85%", fontSize: 26}}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Box>
        <Box sx={{display: 'flex', alignItems: 'flex-end', paddingTop: 2}}>
          <LockIcon sx={{color: '#3a3a3a', mr: 1, my: 0.5}}/>
          <TextField
            id="input-with-sx"
            label="Heslo"
            type='password'
            variant="standard"
            sx={{width: "85%"}}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'center', marginTop: 5}}>
          <Button type='submit' color='primary' variant="contained" sx={{
            paddingLeft: 5,
            paddingRight: 5,
          }}>
            Přihlásit se
          </Button>
        </Box>
      </Paper>
    </form>
  );
}

export default LoginForm;