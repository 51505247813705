import React, { useEffect, useState } from 'react';
import {csCZ} from "@mui/x-data-grid/locales";
import {DataGridPro} from "@mui/x-data-grid-pro";
import { invoicesColumns} from "../../../data/DatatableColumns";

const FinancialInvoices = ({ company, selectedContract }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (company) {
      const filteredRows = company.receivedInvoiceList
        .filter((invoice) => invoice.contract === selectedContract.code)
        .flatMap((invoice, invoiceIndex) =>
          invoice.paymentList.map((payment, paymentIndex) => ({
            id: `${invoiceIndex}-${paymentIndex}`, // Unique ID for each row
            code: invoice.code,
            company: invoice.company,
            description: invoice.description,
            statusPaid: invoice.statusPaid,
            amountBase: invoice.amountBase,
            amountVat: invoice.amountVat,
            amountTotal: invoice.amountTotal,
            paymentAmount: payment.paymentAmount,
            expoDate: invoice.expoDate,
            dueDate: invoice.dueDate,
            paymentDate: payment.paymentDate,
          }))
        );
      setRows(filteredRows);
    }
  }, [company, selectedContract]);

  return (
      <DataGridPro
        columns={invoicesColumns()}
        rows={rows}
        hideFooter
        fullWidth
        localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
        getCellClassName={(params) => {
          if (params.value < 0) {
            return 'negative'
          }
        }}
        sx={{
          height: "68vh",
          width: "100%",
          '&.MuiDataGrid-root': {
            borderRadius: '0px',
            borderLeft: 'none',
            borderRight: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold', overflow: 'visible'
          },
          '& .MuiDataGrid-columnHeader': {
            borderTop: '4px solid rgba(224, 224, 224, 1)',
            backgroundColor: '#f8f8f8',
          },
          '& .negative': {
            color: 'red'
          },
        }}/>
  );
};

export default FinancialInvoices;