import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessIcon from '@mui/icons-material/Business';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DescriptionIcon from '@mui/icons-material/Description';
import HubIcon from '@mui/icons-material/Hub';
import Grid from '@mui/material/Grid2';
import LogoutIcon from '@mui/icons-material/Logout';
import {useSignOut} from "react-auth-kit";
import {useNavigate} from "react-router-dom";
import {toast} from "react-hot-toast";
import {Link} from "react-router-dom";
import logo from "../data/images/NOHO-dlouhe-modra_RGB 3.svg";
import {useContext} from "react";
import {GlobalDataContext} from "./GlobalDataProvider";

const drawerWidth = 200;

function ResponsiveDrawer(props) {
  const {userDetails} = useContext(GlobalDataContext);
  const {window} = props;
  const {children} = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const signOut = useSignOut();
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const handleLogout = () => {
    signOut();
    navigate("/");
    toast.success("Byl jste úspěšně odhlášen!");
  }

  const drawer = (
    <div style={{backgroundColor: '#003366', height: '100%', color: 'white'}}>
      <Link to="/home" style={{textDecoration: 'none', color: 'white'}}>
        <Toolbar sx={{backgroundColor: '#003366', justifyContent: 'center', color: 'white'}}>
          <img src={logo} alt="logo" style={{width: '100%'}}/>
        </Toolbar>
      </Link>
      <List>
        <ListItem key="Title" disablePadding>
          <Typography sx={{paddingLeft: 1.2, fontWeight: "bold", fontSize: "0.85rem"}}>
            Ekonomická data
          </Typography>
        </ListItem>
        {
          userDetails && userDetails.systemVisibilityList?.includes("Skupina") &&
          <Link to="/groups" style={{textDecoration: 'none', color: 'white'}}>
            <ListItem key="Group" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <GroupsIcon sx={{color: 'white'}}/>
                </ListItemIcon>
                <ListItemText primary="Skupina"/>
              </ListItemButton>
            </ListItem>
          </Link>
        }

        {
          userDetails && userDetails.systemVisibilityList?.includes("Činnosti") &&
          <Link to="/activities" style={{textDecoration: 'none', color: 'white'}}>
            <ListItem key="Activities" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <TrendingUpIcon sx={{color: 'white'}}/>
                </ListItemIcon>
                <ListItemText primary="Činnosti"/>
              </ListItemButton>
            </ListItem>
          </Link>
        }

        {
          userDetails && userDetails.systemVisibilityList?.includes("Střediska") &&
          <Link to="/centres" style={{textDecoration: 'none', color: 'white'}}>
            <ListItem key="Centres" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <HubIcon sx={{color: 'white'}}/>
                </ListItemIcon>
                <ListItemText primary="Střediska"/>
              </ListItemButton>
            </ListItem>
          </Link>
        }
        {
          userDetails && userDetails.systemVisibilityList?.includes("Firmy") &&
          <Link to="/companies" style={{textDecoration: 'none', color: 'white'}}>
            <ListItem key="Companies" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <BusinessIcon sx={{color: 'white'}}/>
                </ListItemIcon>
                <ListItemText primary="Firmy"/>
              </ListItemButton>
            </ListItem>
          </Link>
        }
      </List>
      {
        userDetails && userDetails.role !== "user" &&
      <List>
        <ListItem key="Title" disablePadding>
          <Typography sx={{paddingLeft: 1.2, fontWeight: "bold", fontSize: "0.85rem"}}>
            Další
          </Typography>
        </ListItem>
        <Link to="/tasks" style={{textDecoration: 'none', color: 'white'}}>
        <ListItem key="Tasks" disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <AssignmentIcon sx={{color: 'white'}}/>
            </ListItemIcon>
            <ListItemText primary="Úkoly"/>
          </ListItemButton>
        </ListItem>
        </Link>
        <ListItem key="Documents" disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <DescriptionIcon sx={{color: 'white'}}/>
            </ListItemIcon>
            <ListItemText primary="Dokumenty"/>
          </ListItemButton>
        </ListItem>
        <Link to="/users" style={{textDecoration: 'none', color: 'white'}}>
          <ListItem key="Users" disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <PeopleIcon sx={{color: 'white'}}/>
              </ListItemIcon>
              <ListItemText primary="Uživatelé"/>
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
      }
      <List>
        <ListItem key="Title" disablePadding>
          <Typography sx={{paddingLeft: 1.2, fontWeight: "bold", fontSize: "0.85rem"}}>
            Profil
          </Typography>
        </ListItem>
        <ListItem key="Logout" disablePadding  onClick={handleLogout}>
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon sx={{color: 'white'}}/>
            </ListItemIcon>
            <ListItemText primary="Odhlásit se"/>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline/>
      <AppBar
        position="fixed"
        sx={{
          width: {sm: `calc(100% - ${drawerWidth}px)`},
          backgroundColor: '#003366',
          ml: {sm: `${drawerWidth}px`},
          display: {
            xs: 'block',
            sm: 'none',
          }
        }}
      >
        <Toolbar>
          <Grid container>
            <Grid size={6}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{mr: 2, display: {sm: 'none'}}}
              >
                <MenuIcon/>
              </IconButton>
            </Grid>
            <Grid size={6}
                  sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer'}}
                  onClick={handleLogout}>
              <Typography variant="text" noWrap component="div" sx={{color: 'white', paddingRight: 2}}>
                Odhlásit se
              </Typography>
              <LogoutIcon sx={{color: 'white'}}/>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {xs: 'block', sm: 'none'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: {xs: 'none', sm: 'block'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
      >
        <Toolbar sx={{display: {xs: 'block', sm: 'none'}}}/>
        {children}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;