import Box from "@mui/material/Box";
import {FormControlLabel, Switch, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {formatCompanyName} from "../../../utils/Formater";

export const UserPermission = ({formik, flexiWorkspaceList, listOfModules}) => {

  const handleModuleToggle = (module) => (event) => {
    const { checked } = event.target;
    formik.setFieldValue(
      'systemVisibilityList',
      checked
        ? [...formik.values.systemVisibilityList, module]
        : formik.values.systemVisibilityList.filter((m) => m !== module)
    );
  };

  const handleCompanyToggle = (company) => (event) => {
    const { checked } = event.target;
    formik.setFieldValue(
      'flexiWorkspaceList',
      checked
        ? [...formik.values.flexiWorkspaceList, company]
        : formik.values.flexiWorkspaceList.filter((c) => c !== company)
    );
  };

  const handleToggleAllModules = (event) => {
    const { checked } = event.target;
    formik.setFieldValue(
      'systemVisibilityList',
      checked ? listOfModules : []
    );
  };

  const handleToggleAllCompanies = (event) => {
    const { checked } = event.target;
    formik.setFieldValue(
      'flexiWorkspaceList',
      checked ? flexiWorkspaceList : []
    );
  };

  return (
    <Box>
      <Typography variant="subtitle2" fontWeight="bold">
        Moduly:
      </Typography>
      <Grid container spacing={1} sx={{ p: 1 }}>
        <Grid size={12}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={formik.values.systemVisibilityList.length === listOfModules.length}
                onChange={handleToggleAllModules}
              />
            }
            label="Vše"
          />
        </Grid>
        {listOfModules.map((module) => (
          <Grid size={6} key={module}>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={formik.values.systemVisibilityList.includes(module)}
                  onChange={handleModuleToggle(module)}
                />
              }
              label={module}
            />
          </Grid>
        ))}
      </Grid>
      <Typography variant="subtitle2" fontWeight="bold" paddingTop={1}>
        Firmy:
      </Typography>
      <Grid container spacing={1.5} sx={{ p: 1 }}>
        <Grid size={12}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={
                  formik.values.flexiWorkspaceList.length === flexiWorkspaceList.length
                }
                onChange={handleToggleAllCompanies}
              />
            }
            label="Vše"
          />
        </Grid>
        {flexiWorkspaceList.map((company) => (
          <Grid size={6} key={company}>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={formik.values.flexiWorkspaceList.includes(company)}
                  onChange={handleCompanyToggle(company)}
                />
              }
              label={formatCompanyName(company)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};