import Grid from "@mui/material/Grid2";
import {
    Autocomplete,
    FormControl,
    FormHelperText,
    InputAdornment,
    MenuItem, OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import {NumericFormat} from "react-number-format";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {csCZ} from "@mui/x-date-pickers/locales";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {removeTimezone} from "../../../utils/Formater";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};


export const ContractInfo =({ formik, sortedCentres, groupListOptions, phaseListOptions, dictionaryListOptions }) => {

    return (
        <Grid container spacing={2} justifyContent="space-around">
            <Grid size={6}>
                *Zak:
                <TextField
                    name="code"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formik.values.code}
                    onChange={(e) => formik.setFieldValue("code", e.target.value.toUpperCase())}
                    onBlur={formik.handleBlur}
                    error={formik.touched.code && Boolean(formik.errors.code)}
                    helperText={formik.touched.code && formik.errors.code}
                />
            </Grid>
            <Grid size={6}>
                *Název:
                <TextField
                    name="description"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                />
            </Grid>
            <Grid size={6}>
                Stav:
                <br/>
                <FormControl fullWidth error={formik.touched.status && Boolean(formik.errors.status)}>
                    <Select
                        labelId="role-label"
                        id="status"
                        name="status"
                        size="small"
                        fullWidth
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        helperText={formik.touched.status && formik.errors.status}
                        onBlur={formik.handleBlur}
                    >
                        <MenuItem value="PLAN">PLAN</MenuItem>
                        <MenuItem value="PROBIHA">PROBIHA</MenuItem>
                        <MenuItem value="UKONCENO">UKONCENO</MenuItem>
                        <MenuItem value="STORNO">STORNO</MenuItem>
                    </Select>
                    <FormHelperText>{formik.touched.status && formik.errors.status}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid size={6}>
                *Středisko:
                <Autocomplete

                    sx={{ minWidth: "100%" }}
                    value={
                        sortedCentres.find((centre) => centre.id === formik.values.costCenter) || null
                    } // Find the current value in sortedCentres
                    onChange={(e, newValue) =>
                        formik.setFieldValue("costCenter", newValue ? newValue.id : "")
                    } // Update formik's state when the value changes
                    options={sortedCentres} // Pass the list of options
                    getOptionLabel={(option) => `${option.code} - ${option.name}`} // Display code and name
                    isOptionEqualToValue={(option, value) => option.id === value.id} // Compare options and value
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="costCenter"
                            onBlur={formik.handleBlur}
                            size="small"
                            error={formik.touched.costCenter && !!formik.errors.costCenter} // Highlight error
                            helperText={formik.touched.costCenter && formik.errors.costCenter} // Show error message
                        />
                    )}
                />
            </Grid>
            <Grid size={6}>
                Plán:
                <NumericFormat
                    customInput={TextField} // Use MUI TextField as the input component
                    value={formik.values.costAssumption} // Bind to Formik's value
                    onValueChange={(values) =>
                        formik.setFieldValue("costAssumption", values.floatValue || 0)
                    } // Update Formik's state on change
                    onFocus={(e) => e.target.select()} // Select all text on focus
                    thousandSeparator=" " // Format with a space as the thousands separator
                    decimalSeparator="," // Use a comma as the decimal separator
                    decimalScale={0} // Restrict to 0 decimal places
                    allowNegative={false} // Disable negative values
                    isNumericString={true} // Treat as a numeric string
                    InputProps={{
                        endAdornment: <InputAdornment position="start">Kč</InputAdornment>, // Add currency symbol
                    }}
                    size="small"
                    fullWidth
                    error={formik.touched.costAssumption && !!formik.errors.costAssumption} // Show error state
                    helperText={formik.touched.costAssumption && formik.errors.costAssumption} // Display error message
                />
            </Grid>
            <Grid size={6}>
                Smlouva:
                <NumericFormat
                    customInput={TextField} // Use MUI TextField as the input component
                    value={formik.values.scope} // Bind to Formik's value
                    onValueChange={(values) =>
                        formik.setFieldValue("scope", values.floatValue || 0)
                    } // Update Formik's state on change
                    onFocus={(e) => e.target.select()} // Select all text on focus
                    thousandSeparator=" " // Format with a space as the thousands separator
                    decimalSeparator="," // Use a comma as the decimal separator
                    decimalScale={0} // Restrict to 0 decimal places
                    allowNegative={false} // Disable negative values
                    isNumericString={true} // Treat as a numeric string
                    InputProps={{
                        endAdornment: <InputAdornment position="start">Kč</InputAdornment>, // Add currency symbol
                    }}
                    size="small"
                    fullWidth
                    error={formik.touched.scope && !!formik.errors.scope} // Show error state
                    helperText={formik.touched.scope && formik.errors.scope} // Display error message
                />
            </Grid>
            <Grid size={6}>
                *Datum zahájení:
                <LocalizationProvider
                    adapterLocale="cs"
                    dateAdapter={AdapterDayjs}
                    localeText={csCZ.components.MuiLocalizationProvider.defaultProps.localeText}
                >
                    <DatePicker
                        views={['year', 'month', 'day']}
                        value={
                            formik.values.startDate
                                ? dayjs(removeTimezone(formik.values.startDate))
                                : null
                        } // Bind to Formik's value
                        onChange={(newValue) =>
                            formik.setFieldValue(
                                "startDate",
                                newValue ? dayjs(newValue).format('YYYY-MM-DD') + "+00:00" : null
                            )
                        } // Update Formik's state on change
                        maxDate={formik.values.endDate ? dayjs(removeTimezone(formik.values.endDate)) : null}
                        onError={() => formik.setFieldTouched("startDate", true)} // Mark as touched on blur
                        slotProps={{
                            textField: {
                                name: "startDate",
                                onBlur: formik.handleBlur,
                                size: 'small',
                                fullWidth: true,
                                error: formik.touched.startDate && !!formik.errors.startDate, // Pass error state
                                helperText: formik.touched.startDate && formik.errors.startDate, // Pass helper text
                            },
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid size={6}>
                *Datum ukončení:
                <LocalizationProvider
                    adapterLocale="cs"
                    dateAdapter={AdapterDayjs}
                    localeText={csCZ.components.MuiLocalizationProvider.defaultProps.localeText}
                >
                    <DatePicker
                        views={['year', 'month', 'day']}
                        value={
                            formik.values.endDate
                                ? dayjs(removeTimezone(formik.values.endDate))
                                : null
                        }
                        onChange={(newValue) =>
                            formik.setFieldValue(
                                "endDate",
                                newValue ? dayjs(newValue).format('YYYY-MM-DD') + "+00:00" : null
                            )
                        } // Update Formik's state on change
                        minDate={formik.values.startDate ? dayjs(removeTimezone(formik.values.startDate)) : null}
                        onError={() => formik.setFieldTouched("endDate", true)} // Mark as touched on blur
                        slotProps={{
                            textField: {
                                name: "endDate",
                                onBlur: formik.handleBlur,
                                size: 'small',
                                fullWidth: true,
                                error: formik.touched.endDate && !!formik.errors.endDate,
                                helperText: formik.touched.endDate && formik.errors.endDate,
                            },
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid size={6}>
                *Skupina:
                <br />
                <FormControl
                    sx={{ minWidth: "100%" }}
                    error={formik.touched.groupList && !!formik.errors.groupList}
                >
                    <Select
                        name="groupList"
                        onBlur={formik.handleBlur}
                        value={formik.values.groupList || ""} // Bind to Formik value
                        onChange={(e) => formik.setFieldValue("groupList", [e.target.value])} // Update Formik state
                        displayEmpty
                        size="small"
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        {groupListOptions.map((group) => (
                            <MenuItem key={group} value={group}>
                                {group}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                        {formik.touched.groupList && formik.errors.groupList}
                    </FormHelperText>
                </FormControl>
            </Grid>

            <Grid size={6}>
                *Fáze:
                <br />
                <FormControl
                    sx={{ minWidth: "100%" }}
                    error={formik.touched.phaseList && !!formik.errors.phaseList}
                    onBlur={formik.handleBlur}
                >
                    <Select
                        multiple
                        value={formik.values.phaseList || []} // Bind to Formik value
                        onChange={(e) => formik.setFieldValue("phaseList", e.target.value)} // Update Formik state
                        input={<OutlinedInput name="phaseList"
                                              onBlur={formik.handleBlur}/>}
                        MenuProps={MenuProps}
                        sx={{ width: "100%" }}
                        size="small"
                    >
                        {phaseListOptions.map((phase) => (
                            <MenuItem key={phase} value={phase}>
                                {phase}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                        {formik.touched.phaseList && formik.errors.phaseList}
                    </FormHelperText>
                </FormControl>
            </Grid>

            <Grid size={6}>
                Firma:
                <Autocomplete

                  sx={{ minWidth: "100%" }}
                  value={
                    dictionaryListOptions.find((company) => company.name === formik.values.dictionary) || null
                  } // Find the current value in sortedCentres
                  onChange={(e, newValue) =>
                    formik.setFieldValue("dictionary", newValue ? newValue.name : "")
                  } // Update formik's state when the value changes
                  options={dictionaryListOptions} // Pass the list of options
                  getOptionLabel={(option) => `${option.name}`} // Display code and name
                  isOptionEqualToValue={(option, value) => option.name === value.name} // Compare options and value
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="company"
                      onBlur={formik.handleBlur}
                      size="small"
                      error={formik.touched.dictionary && !!formik.errors.dictionary} // Highlight error
                      helperText={formik.touched.dictionary && formik.errors.dictionary} // Show error message
                    />
                  )}
                />
            </Grid>

            <Grid size={6}/>
        </Grid>
    )
};