import {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Button, IconButton, Tab, Tabs, Typography} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from '@mui/icons-material/Delete';
import {GlobalDataContext} from "../../GlobalDataProvider";
import Grid from "@mui/material/Grid2";
import {useAuthHeader} from "react-auth-kit";
import axios from "axios";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {toastWithError} from "../../ToastWithError";
import {generateUserValueListWithDistribution} from "../../../utils/ValueGenerator";
import {useFormik} from "formik";
import {contractSchema} from "../../../utils/Validations";
import {ContractInfo} from "./ContractInfo";
import {ContractFiles} from "./ContractFiles";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%', // Default width for both PC and mobile
    maxWidth: '90vh', // Maximum width for PC
    maxHeight: '90vh', // Adjust the height as needed
    overflowY: 'auto', // Enable vertical scrolling
    boxShadow: 24,
    borderRadius: "10px",
    bgcolor: 'background.paper',
};

const ContractEdit = ({mode, selectedContract, opened, data, close, updateRows, deleteContract}) => {
    const {listOfCentres} = useContext(GlobalDataContext);
    const {getUpdatedDataTime} = useContext(GlobalDataContext);
    const {setUpdateMessage} = useContext(GlobalDataContext);
    const [tab, setTab] = useState(0);
    const [sortedCentres, setSortedCentres] = useState([]);
    const [oldCode, setOldCode] = useState(null);
    const [groupListOptions, setGroupListOptions] = useState([]);
    const [phaseListOptions, setPhaseListOptions] = useState([]);
    const [dictionaryListOptions, setDictionaryListOptions] = useState([]);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const getAuthHeader = useAuthHeader();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            code: null,
            description: null,
            costCenter: null,
            scope: 0,
            costAssumption: 0,
            startDate: null,
            endDate: null,
            status: null,
            userValueList: [],
            groupList: [],
            phaseList: [],
            sourceList: [],
            dictionary: null,
        },
        validationSchema: contractSchema,
        onSubmit: async (values) => {
            submit(values);
        },
    });

    const handleClose = () => {
        close();
        formik.resetForm();
        setTab(0);
    }

    useEffect(() => {
        if (opened) {
            mode === "create" && fetchLabels();
            mode === "update" && fetchUpdateData();
        }
    }, [opened]);

    useEffect(() => {
        if (formik && formik.values.phaseList && formik.values.startDate && formik.values.endDate && mode === "create") {
            const userValueList = generateUserValueListWithDistribution(formik.values.phaseList, formik.values.startDate, formik.values.endDate, formik.values.costAssumption, formik.values.scope,);
            formik.setFieldValue("userValueList", userValueList);
        }

    }, [formik.values.phaseList, formik.values.scope, formik.values.costAssumption, formik.values.startDate, formik.values.endDate]);

    const fetchUpdateData = async () => {
        try {
            formik.setValues({
                code: selectedContract.code === "" ? null : selectedContract.code,
                description: selectedContract.description === "" ? null : selectedContract.description,
                costCenter: selectedContract.costCenter.toString() === "" ? null : selectedContract.costCenter,
                scope: parseFloat(selectedContract.scope),
                costAssumption: parseFloat(selectedContract.costAssumption),
                startDate: selectedContract.startDate === "" ? null : selectedContract.startDate,
                endDate: selectedContract.endDate === "" ? null : selectedContract.endDate,
                status: selectedContract.status === "" ? null : selectedContract.status,
                groupList: selectedContract.groupList,
                phaseList: selectedContract.phaseList,
                userValueList: selectedContract.userValueList,
                dictionary: selectedContract.dictionary,
                sourceList: [],
            });
            setOldCode(selectedContract.code === "" ? null : selectedContract.code);
            fetchLabels();
        } catch (error) {
            console.error(error);
        }
    };

    const fetchLabels = async () => {
        try {
            data.report.labelGroupList.forEach(label => {
                if (label.group) setGroupListOptions(label.labelList);
                if (label.phase) setPhaseListOptions(label.labelList);
            });
            const dictionaryListResponse = await axios.post(
              process.env.REACT_APP_FLEXIOVERVIEW_API_URL + `/dictionary/list`,
              {companyCode: data.report.companyDetail.code},
              {headers: {Authorization: getAuthHeader()}}
            )
            let dictionaryList = dictionaryListResponse.data.dictionary.dictionaryItemList || [];
            let sortedDictionaryList = [...dictionaryList].sort((a, b) => a.name.localeCompare(b.name));
            setDictionaryListOptions(sortedDictionaryList || []);

            const sortedListOfCentres = [...listOfCentres].sort((a, b) => a.code.localeCompare(b.code));
            setSortedCentres(sortedListOfCentres);
        } catch (error) {
            console.error(error);
        }
    };

    const submit = async (values) => {
        try {
            const { code, ...restValues } = values; // Remove code from values
            setSaving(true);
            const token = getAuthHeader();
            if (!token) {
                navigate("/");
                toast.error("Přihlášení vypršelo!");
                return
            }
            const updatingDataset = await axios.get(
              process.env.REACT_APP_FLEXIOVERVIEW_API_URL + `/dataset/get`,
              {headers: {Authorization: token}}
            );
            if (updatingDataset.data.updating) {
                setSaving(false);
                toast.error(`Právě aktualizuji data. Zkuste to znovu později!`);
                setUpdateMessage(updatingDataset.data.message);
                getUpdatedDataTime();
                return
            }
            const response = await axios.post(
              process.env.REACT_APP_FLEXIOVERVIEW_API_URL + `/contract/${mode}`,
              {
                  ...restValues,
                  companyCode: data.report.companyDetail.code,
                  code: mode === "update" ? oldCode : code,
                  updatedCode: mode === "update" ? code : null
              },
              { headers: { Authorization: token } }
            );
            toast.success(`Zakázka byla ${mode === "create" ? "vytvořena" : "upravená"}!`);
            const newContract = response.data.flexiContract;
            const newRow = {
                code: newContract.code,
                costCenter: newContract.costCenter,
                description: newContract.description,
                startDate: newContract.startDate,
                endDate: newContract.endDate,
                scope: newContract.scope,
                costAssumption: newContract.costAssumption,
                status: newContract.status,
                userValueList: newContract.userValueList,
                groupList: newContract.groupList,
                phaseList: newContract.phaseList,
                sourceList: newContract.sourceList,
                dictionary: newContract.dictionary
            }
            updateRows(mode === "update" ? oldCode : code, newRow, newContract.costCenter);
            handleClose();
            setSaving(false);

        } catch (error) {
            console.error(error);
            toastWithError(error);
            toast.error(`Zakázku se nepodařilo ${mode === "create" ? "vytvořit" : "upravit"}!`);
            setSaving(false);
        }
    };

    const handleDelete = async () => {
        try {
            setDeleting(true);
            const token = getAuthHeader();
            if (!token) {
                setDeleting(false);
                navigate("/");
                toast.error("Přihlášení vypršelo!");
                return
            }
            const updatingDataset = await axios.get(
              process.env.REACT_APP_FLEXIOVERVIEW_API_URL + `/dataset/get`,
              {headers: {Authorization: token}}
            );
            if (updatingDataset.data.updating) {
                setDeleting(false);
                toast.error(`Právě aktualizuji data. Zkuste to znovu později!`);
                setUpdateMessage(updatingDataset.data.message);
                return
            }
            const response = await axios.post(
              process.env.REACT_APP_FLEXIOVERVIEW_API_URL + `/contract/delete`,
              {
                  companyCode: data.report.companyDetail.code,
                  code: selectedContract.code
              },
              {headers: {Authorization: token}}
            );
            toast.success(`Zakázka byla smazána!`);
            deleteContract(response.data.flexiContract.code, response.data.flexiContract.costCenter);
            handleClose();
            setDeleting(false);
        } catch (error) {
            console.error(error);
            toastWithError(error);
            toast.error(`Zakázku se nepodařilo smazat!`);
            setDeleting();
        }
    };


    return (
      <Modal open={opened} onClose={handleClose} aria-labelledby="user-edit-modal" aria-describedby="user-edit-form">
          <Box sx={style} component="form" onSubmit={formik.handleSubmit}>
              <Box sx={{position: 'sticky', top: 0, backgroundColor: '#ffffff', zIndex: 1}}>
                  <Grid container>
                      <Grid size={6}>
                          <Typography variant="h5" sx={{p: 3, paddingBottom: "10px", fontWeight: 'bold'}}>
                              {mode === 'edit' ? 'Úprava zakázky' : 'Vytváření nové zakázky'}
                          </Typography>
                      </Grid>
                      <Grid size={6} sx={{display: 'flex', justifyContent: 'flex-end', p:1.5, pb:0}}>
                          <IconButton onClick={handleClose} >
                              <CloseIcon/>
                          </IconButton>
                      </Grid>
                  </Grid>
                  <Tabs value={tab} sx={{paddingLeft: 3, paddingRight: 3}}
                        onChange={(e, newValue) => setTab(newValue)}>
                      <Tab label="Základní údaje"/>
                      <Tab label="Přílohy"/>
                  </Tabs>
              </Box>
              <Box sx={{p: 3, minHeight: '35vh', backgroundColor: '#f8f8f8'}}>
                  <Box hidden={tab !== 0}>
                      <ContractInfo formik={formik} sortedCentres={sortedCentres} groupListOptions={groupListOptions}
                                    phaseListOptions={phaseListOptions} dictionaryListOptions={dictionaryListOptions}/>
                  </Box>
                  <Box hidden={tab !== 1}>
                      <ContractFiles/>
                  </Box>
              </Box>
              <Box sx={{position: 'sticky', bottom: 0, backgroundColor: '#ffffff'}}>
                  <Grid container sx={{p: 3}}>
                      <Grid size={6}>
                          {mode === "update" &&
                            <LoadingButton variant="outlined" disabled={saving} color="error" loading={deleting}
                                           loadingPosition="start" onClick={handleDelete} startIcon={<DeleteIcon/>}>
                                Smazat
                            </LoadingButton>

                          }
                      </Grid>
                      <Grid size={6} sx={{display: tab === 0 ? 'flex' : 'none', justifyContent: 'flex-end', gap: 2}}>
                          <Button variant="outlined" onClick={handleClose} disabled={saving || deleting}
                                  alignSelf="flex-start"
                                  startIcon={<CloseIcon/>}>
                              Zrušit
                          </Button>
                          <LoadingButton variant="contained" type="submit" loading={saving} disabled={deleting}
                                         loadingPosition="end" sx={{marginLeft: 2}}
                                         endIcon={<SaveIcon/>}>
                              Uložit
                          </LoadingButton>
                      </Grid>
                  </Grid>
              </Box>
          </Box>
      </Modal>
    );
};
export default ContractEdit;
